var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "UsersIcon",
      "size": "30"
    }
  }), _vm._v(" Profissionais Cadastrados ")], 1), _c('h4', {
    staticClass: "mt-1"
  }, [_vm._v("Selecione um profissional:")])]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "button-right ml-1 botao-white-outline",
    attrs: {
      "variant": "outline-primary",
      "to": "/novoProfissional"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "PlusIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Adicionar Profissional ")])], 1)], 1)], 1)], 1)], 1), _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.fetchProfissionais.apply(null, arguments);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-2"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Primeiro Nome do Profissional"
    },
    model: {
      value: _vm.filtro.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "firstName", $$v);
      },
      expression: "filtro.firstName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-2"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Útimo Nome do Profissional"
    },
    model: {
      value: _vm.filtro.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "lastName", $$v);
      },
      expression: "filtro.lastName"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-2"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "CPF do Profissional"
    },
    model: {
      value: _vm.filtro.cpf,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "cpf", $$v);
      },
      expression: "filtro.cpf"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-2"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Cidade do Profissional"
    },
    model: {
      value: _vm.filtro.city,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "city", $$v);
      },
      expression: "filtro.city"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-input-group', {
    staticClass: "input-group-merge mb-2"
  }, [_c('b-input-group-prepend', {
    attrs: {
      "is-text": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "SearchIcon"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "placeholder": "Estado do Profissional"
    },
    model: {
      value: _vm.filtro.state,
      callback: function callback($$v) {
        _vm.$set(_vm.filtro, "state", $$v);
      },
      expression: "filtro.state"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "type": "submit",
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.fetchProfissionais();
      }
    }
  }, [_vm._v(" Filtrar ")])], 1)], 1)], 1), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-table', {
    ref: "refUserListTable",
    staticClass: "position-relative",
    staticStyle: {
      "min-height": "50vw"
    },
    attrs: {
      "items": _vm.fetchedProfissionais,
      "responsive": "",
      "fields": _vm.tableColumns,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": "No matching records found",
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(nome)",
      fn: function fn(data) {
        return [_c('h5', [_vm._v(_vm._s(data.item.firstName) + " " + _vm._s(data.item.lastName))])];
      }
    }, {
      key: "cell(cadastro)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.createdAt) + " ")];
      }
    }, {
      key: "cell(localizacao)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.person.city) + " - " + _vm._s(data.item.person.state) + " ")];
      }
    }, {
      key: "cell(user)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center"
          },
          scopedSlots: _vm._u([{
            key: "aside",
            fn: function fn() {
              return [_c('b-avatar', {
                attrs: {
                  "size": "32",
                  "src": data.item.avatar,
                  "text": _vm.avatarText(data.item.fullName),
                  "variant": "light-".concat(_vm.resolveUserRoleVariant(data.item.role)),
                  "to": {
                    name: 'apps-users-view',
                    params: {
                      id: data.item.id
                    }
                  }
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap",
          attrs: {
            "to": {
              name: 'apps-users-view',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(data.item.fullName) + " ")]), _c('small', {
          staticClass: "text-muted"
        }, [_vm._v("@" + _vm._s(data.item.username))])], 1)];
      }
    }, {
      key: "cell(role)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          class: "text-".concat(_vm.resolveUserRoleVariant(data.item.role)),
          attrs: {
            "icon": _vm.resolveUserRoleIcon(data.item.role),
            "size": "18"
          }
        }), _c('span', {
          staticClass: "align-text-top text-capitalize"
        }, [_vm._v(_vm._s(data.item.role))])], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          staticClass: "text-capitalize",
          attrs: {
            "pill": "",
            "variant": "light-".concat(_vm.resolveUserStatusVariant(data.item.person.status))
          }
        }, [_vm._v(" " + _vm._s(_vm.resolveUserStatusText(data.item.person.status)) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "no-caret": "",
            "right": _vm.$store.state.appConfig.isRTL
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "align-middle text-body",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'profissionalEdit',
              params: {
                id: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        }), _c('span', {
          staticClass: "align-middle ml-50"
        }, [_vm._v("Editar Profissional")])], 1)], 1)];
      }
    }])
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }